@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    @include main-column;
    padding: 30px 20px;
}

.title{
    @include title;
    margin-bottom: 10px;
    max-width: 500px
}

.list{
    max-width: 1400px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    gap: 20px;
    padding: 20px 10px;
    overflow: hidden;
}

.cell{
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(37, 37, 37, .3);
    background: $box;
    overflow: hidden;

    &:hover{
        cursor: pointer;
        transform:scale(1.05);
        transition: .5s;
    }
}

.cell_image{
    width: 100%;
}

.cell_info{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 20px;
}

.cell_subtitle{
    font-size: 20px;
    line-height: 1.2;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    max-width: 300px;
    color: $title;
}

.cell_days {
    font-size: 16px;
    line-height: 1.2;
    font-weight: 600;
    color: $element;
    margin: 10px 0 10px;
}

.cell_text {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    color: $text;
    height: 96px;
}

.cell_years {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    color: $text;
}

.cell_button{
    @include button;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    max-width: 350px;
    width: 100%;
    height: 40px;
    margin: 20px 0 0;
}

.glare{
    @include glare
}

@media(max-width: 1280px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}

@media(max-width: 900px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media(max-width: 768px){
    .title{
        @include title;
        margin-bottom: 0;
    }

    .box{
        padding: 10px;
    }

    .cell_button{
        height: 40px;
    }

    .list{
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    }
}

@media(max-width: 425px){
    .box{
        padding: 10px;
    }

    .list{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .cell_button{
        height: 40px;
    }
}