@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    padding: 0 20px;
}

.box_info{
    box-sizing: border-box;
}


.title{
    @include title;
    max-width: 100%;
    width: 100%;
    text-align: left
}

.text{
    @include text;
    margin: 10px 0 0; 
}

.image{
    width: 600px;
    aspect-ratio: 1/1;
    border-radius: 6px;
    float:left;
    margin: 0 20px 0 0;
}

.box_link {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.link {
    @include button;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.glare{
    @include glare
}


@media(max-width: 1080px){
    .image{
        width: 45%;
    }

    .title{
        max-width: 100%;
        width: 100%;
    }
}


@media(max-width: 768px){

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;   
        padding: 0 10px;
    }

    .image{
        width: 100%;
        margin: 0;
    }

    .title{
        max-width: 500px;
        width: 100%;
    }
    
    .box_info{
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;  
    }
}


@media(max-width: 425px){

    .container{
    padding: 0 10px;
    }
}