@import '../../base.scss';

    .main{
        @include main-column;
    }

    .box{
        box-sizing: border-box;
        max-width: 1440px;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 80px 105px 100px;
        background-color: $box;
        border-radius: 26px;
    }

    .box_text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: 1;
    }

    .title{
        @include title;
        text-align: left;
        max-width: 700px;
    }

    .text{
        @include text-box;
    }

    .button {
        @include button;
        margin-top: 30px;
    }

    .glare{
        @include glare
    }

    .image {
        position: absolute;
        bottom: 0;
        right: 10%;
        width: 420px;
        height: auto;
        z-index: 0;
    }

    @media(max-width: 1080px){
        .title{
            font-size: 36px;
            max-width: 550px;
        }

        .image {
            right: 2%;
            width: 400px;
        }
    }

    @media(max-width: 900px){

        .box{
            padding: 80px 30px 100px;
        }

        .image {
            right: -20px;
            width: 400px;
        }
    }

    @media(max-width: 768px){
        .box{
            padding: 40px 30px 310px;
        }

        .image {
            bottom: 0;
            right: 0;
            width: 400px;
        }
    }

    @media(max-width: 580px){

        .box{
            flex-direction: column;
            gap: 10px;
            padding: 40px 30px;
        }

        .title{
            font-size: 30px;
        }

        .image {
            position: relative;
            bottom: 0;
            right: 0;
            width: 100%;
        }
    }

    @media(max-width: 500px){

        .title{
            font-size: 28px;
        }
    }

    @media(max-width: 425px){

        .title{
            font-size: 26px;
        }

        .box{
            padding: 30px 20px;
        }
    }