@font-face {
	font-family:'Trajan';
	font-style:  normal;
	font-weight: 400;
	font-display: swap;
	src: url("Trajan.woff2") format("woff2"),
		url("Trajan.woff") format("woff");
}


@font-face {
	font-family:'Lovelace';
	font-style:  normal;
	font-weight: 400;
	font-display: swap;
	src: url("LovelaceRegular.woff2") format("woff2"),
		url("LovelaceRegular.woff") format("woff");
}

@font-face {
	font-family:'Montserrat';
	font-style:  normal;
	font-weight: 300;
	font-display: swap;
	src: url("Montserrat-Light.woff2") format("woff2"),
		url("Montserrat-Light.woff") format("woff");
}

@font-face {
	font-family:'Montserrat';
	font-style:  normal;
	font-weight: 400;
	font-display: swap;
	src: url("Montserrat-Regular.woff2") format("woff2"),
		url("Montserrat-Regular.woff") format("woff");
}

@font-face {
	font-family:'Montserrat';
	font-style:  normal;
	font-weight: 500;
	font-display: swap;
	src: url("Montserrat-Medium.woff2") format("woff2"),
		url("Montserrat-Medium.woff") format("woff");
}

@font-face {
	font-family:'Montserrat';
	font-style:  normal;
	font-weight: 600;
	font-display: swap;
	src: url("Montserrat-SemiBold.woff2") format("woff2"),
		url("Montserrat-SemiBold.woff") format("woff");
}

@font-face {
	font-family:'Montserrat';
	font-style:  normal;
	font-weight: 700;
	font-display: swap;
	src: url("Montserrat-Bold.woff2") format("woff2"),
		url("Montserrat-Bold.woff") format("woff");
}

@font-face {
	font-family:'Montserrat';
	font-style:  normal;
	font-weight: 800;
	font-display: swap;
	src: url("Montserrat-ExtraBold.woff2") format("woff2"),
		url("Montserrat-ExtraBold.woff") format("woff");
}

@font-face {
	font-family:'Montserrat';
	font-style:  normal;
	font-weight: 900;
	font-display: swap;
	src: url("Montserrat-Black.woff2") format("woff2"),
		url("Montserrat-Black.woff") format("woff");
}