@import '../../base.scss';

@keyframes wheel-rotation {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.box{
    @include main-column;
    align-items: flex-start;
    padding-bottom: 30px
}

.container{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    @include title
}

.list{
    box-sizing: border-box;
    width: 100%;
    margin-top: 20px;
    padding: 0 20px;
}

@media(max-width: 768px){
    .list{
        padding: 0 10px;
    }
}

